import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearchPlus } from "react-icons/fa";
import Layout from "../components/common/Layout";

export default function Verify() {
  const [certificateId, setCertificateId] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (certificateId) {
      navigate(`/verify/${certificateId}`);
    }
  };

  return (
    <Layout>
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="mb-8">
          <FaSearchPlus className="w-24 h-24 text-blue-500" />
        </div>
        <h1 className="text-3xl font-bold mb-6">Verify Certificate</h1>
        <form onSubmit={handleSubmit} className="w-full max-w-md">
          <input
            type="text"
            placeholder="Enter Certificate ID"
            value={certificateId}
            onChange={(e) => setCertificateId(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Verify
          </button>
        </form>
      </div>
    </Layout>
  );
}
