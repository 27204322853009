import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import data from "./data.json";

export default function IndividualVerify() {
  const { id } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const certificate = data.find((certificate) => certificate.id === id);
    setCertificate(certificate);
    setLoading(false);
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!certificate) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-red-50 to-pink-100">
        <div className="bg-white p-10 rounded-2xl shadow-xl text-center max-w-md w-full mx-4 border border-gray-100">
          <div className="mb-6">
            <FaTimesCircle className="text-red-500 text-6xl mx-auto animate-pulse" />
          </div>
          <h2 className="text-3xl font-bold text-gray-800 mb-4 bg-gradient-to-r from-red-500 to-pink-500 bg-clip-text text-transparent">
            Certificate Not Found
          </h2>
          <div className="h-1 w-20 bg-gradient-to-r from-red-500 to-pink-500 mx-auto mb-6 rounded-full"></div>
          <p className="text-gray-700 text-lg leading-relaxed">
            We couldn't locate the certificate you're looking for. Please verify
            the certificate ID and try again.
          </p>
          <div className="mt-8 p-4 bg-gray-50 rounded-xl">
            <p className="text-sm text-gray-500">
              Verification Attempt: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="bg-white p-10 rounded-2xl shadow-xl text-center max-w-md w-full mx-4 border border-gray-100">
        <div className="mb-6">
          <FaCheckCircle className="text-green-500 text-6xl mx-auto animate-bounce" />
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4 bg-gradient-to-r from-green-500 to-blue-500 bg-clip-text text-transparent">
          Certificate Verified
        </h2>
        <div className="h-1 w-20 bg-gradient-to-r from-green-500 to-blue-500 mx-auto mb-6 rounded-full"></div>
        <p className="text-gray-700 text-lg leading-relaxed">
          {certificate.message}
        </p>
        <div className="mt-8 p-4 bg-gray-50 rounded-xl">
          <p className="text-sm text-gray-500">
            Verification Date: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
}
